import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { useValidation } from 'hooks/useValidation'
import Template from 'components/auth/components/Template'
import FormInput from 'components/auth/components/FormInput'
import Button from 'components/auth/components/button'
import ErrorText from 'components/auth/components/ErrorText'
import { useSignUp } from 'hooks/api/useSignUp'
import { useHistory } from 'react-router-dom'
import Link from '../components/Link'

const SignUp: React.FC = () => {
  const history = useHistory()
  const [styles, setStyles] = useStyles({
    page: 'components/auth',
  })

  const [name, setName] = React.useState<string>()
  const [email, setEmail] = React.useState<string>()
  const [password, setPassword] = React.useState<string>()
  const [nameValidation, setNameValidation] = useValidation({
    type: 'contributor_name',
    value: name,
  })
  const [emailValidation, setEmailValidation] = useValidation({
    type: 'email',
    value: email,
  })
  const [passwordValidation, setPasswordValidation] = useValidation({
    type: 'password',
    value: password,
  })
  const [signUp] = useSignUp({ name, email, password })

  const nameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameValidation('contributor_name', event.target.value)
    setName(event.target.value)
  }

  const emailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValidation('email', event.target.value)
    setEmail(event.target.value)
  }

  const passwordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValidation('password', event.target.value)
    setPassword(event.target.value)
  }

  const buttonActivationCheck = () => {
    if (
      nameValidation?.type === 'error' ||
      emailValidation?.type === 'error' ||
      passwordValidation?.type === 'error'
    )
      return true
    return false
  }

  if (!styles) return <></>

  return (
    <Template label={'新規登録'} styles={styles}>
      {emailValidation?.type === 'error' && (
        <ErrorText message={emailValidation.message} styles={styles} />
      )}
      {passwordValidation?.type === 'error' && (
        <ErrorText message={passwordValidation.message} styles={styles} />
      )}
      <FormInput
        label="名前"
        type="text"
        placeholder="名前"
        onChangeHandler={nameHandler}
        styles={styles}
      />
      <FormInput
        label="メールアドレス"
        type="email"
        placeholder="メールアドレス"
        onChangeHandler={emailHandler}
        styles={styles}
      />
      <FormInput
        label="パスワード"
        type="password"
        placeholder="英数字を含む8文字のパスワード"
        onChangeHandler={passwordHandler}
        styles={styles}
      />
      <div className={styles.default.form_submit_btn_spacer} />
      <Button
        label="新規登録"
        disabled={buttonActivationCheck()}
        onClickHandler={() => {
          if (
            nameValidation?.type === 'error' ||
            emailValidation?.type === 'error' ||
            passwordValidation?.type === 'error'
          ) {
            return
          }

          signUp.mutate()
        }}
      />
      <section className={styles.default.other_options}>
        <Link
          label="ログイン"
          onClickHandler={() => history.push('/sign_in')}
          styles={styles}
        />
      </section>
    </Template>
  )
}

export default SignUp
