import tagsDeserializer from 'deserializers/tags/many'
import { Contributors } from 'models/contributors'
import xAccountDeserializer from 'deserializers/x_account'

function contributorsDeserializer(input: {
  data: any
  meta?: any
}): Contributors {
  return {
    data: input.data.map((item: any) => ({
      id: item.id,
      name: item.name,
      englishName: item.english_name,
      email: item.email,
      description: item.description,
      profiles: item.profiles,
      career: item.career,
      twitterLink: item.twitter_link,
      notificationEmail: item.notification_email,
      tags: tagsDeserializer({ data: item.tags }),
      icon: item.icon,
      subscribersCount: item.subscribers_count,
      userId: item.user_id,
      authType: item.auth_type,
      productId: item.product_id,
      isApproved: item.is_approved,
      xAccount: xAccountDeserializer({ data: item.contributor_x_account }),
      approvedAt: item.approved_at,
      affiliation: item.affiliation,
      termsAndConditions: item.terms_and_conditions,
    })),
    meta: input.meta && {
      currentPage: input.meta.current_page,
      nextPage: input.meta.next_page,
      prevPage: input.meta.prev_page,
      totalCount: input.meta.total_count,
      totalPages: input.meta.total_pages,
    },
  }
}

export default contributorsDeserializer
