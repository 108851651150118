import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { getContributor } from 'actions/contributors'
import {
  getContributorMessages,
  getReservedContributorMessages,
  deleteContributorMessage,
} from 'actions/messages'
import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import { ContributorsState } from 'models/contributors'
import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import Button from 'components/modules/button'
import Modal from 'components/modules/modal'
import ModalButton from 'components/modules/modal/components/button'
import Overlay from 'components/modules/overlay'
import Toast from 'components/modules/toast'
import { ReactComponent as Create } from 'images/create.svg'
import { ReactComponent as Bucket } from 'images/bucket.svg'
import { useStyles } from 'hooks/useStyles'
import { validateSession } from 'utils/session'
import Pagination from 'components/modules/pagination'
import './index.css'
import { useContributor } from 'hooks/useContributor'
import { ToastsState } from 'models/toasts'
import {
  ContributorMessage,
  MessageContent,
  MessagesState,
} from 'models/messages'

const Messages: React.FC = () => {
  const [styles, setStyles] = useStyles({
    page: 'components/messages/list',
  })

  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<{
    id: string
  }>()

  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )
  const messagesState: MessagesState = useSelector((state: CombineReducers) => {
    return state.messages
  })
  const toastsState: ToastsState = useSelector((state: CombineReducers) => {
    return state.toasts
  })

  const [user, contributor] = useContributor({ queryString: params })

  const [selectedTab, setSelectedTab] = useState<{
    reserved: boolean
    done: boolean
  }>({ reserved: false, done: true })

  const [removeMessageModal, setRemoveMessageModal] = useState<boolean>(false)
  const [removeTarget, setRemoveTarget] = useState<number>()

  const selectItemHandler = (messageId: number) =>
    history.push(
      `/contributors/${
        contributorsState.currentContributor!.id
      }/messages/${messageId}`
    )

  const showRemoveMessageModalHandler = (targetId: number) => {
    setRemoveMessageModal(true)
    setRemoveTarget(targetId)
  }

  const removeMessage = () => {
    setRemoveMessageModal(false)
    if (removeTarget)
      dispatch(deleteContributorMessage.started({ id: removeTarget }))
  }

  const tabHandler = (type: string) => {
    switch (type) {
      case 'reserved':
        setSelectedTab({
          reserved: true,
          done: false,
        })
        fetchAllReservedMessages(1)
        break
      case 'done':
        setSelectedTab({
          reserved: false,
          done: true,
        })
        fetchAllMessages(1)
        break
    }
  }

  const parseDateTime = (datetime: string) => {
    return moment(new Date(datetime)).format('YYYY/MM/DD HH:mm')
  }

  const fetchAllMessages = (page: number) => {
    if (sessionsState.currentUser) {
      if (sessionsState.currentUser.authType === 'Administrator') {
        if (contributorsState.currentContributor) {
          dispatch(
            getContributorMessages.started({
              contributorId: contributorsState.currentContributor.id,
              page,
            })
          )
        }
      } else {
        dispatch(
          getContributorMessages.started({
            contributorId: sessionsState.currentUser.contributor.id,
            page,
          })
        )
      }
    }
  }

  const fetchAllReservedMessages = (page: number) => {
    if (sessionsState.currentUser) {
      dispatch(
        getReservedContributorMessages.started({
          contributorId:
            sessionsState.currentUser.authType === 'Administrator'
              ? contributorsState.currentContributor!.id
              : sessionsState.currentUser.contributor.id,
          page,
        })
      )
    }
  }

  const distributionLabel = () => {
    if (contributorsState.currentContributor)
      return `${contributorsState.currentContributor.name}の配信`
    return 'メッセージ配信'
  }

  const screenMediaTypes = (
    message: ContributorMessage
  ): {
    url: string
    clickable: boolean
    type?: 'IMAGE' | 'VIDEO' | 'VOICE'
  } => {
    const image = message.messageContents.data.find(
      (messageContent: MessageContent) => messageContent.contentType === 'image'
    )
    if (image) return { url: image.image, clickable: true, type: 'IMAGE' }
    const video = message.messageContents.data.find(
      (messageContent: MessageContent) => messageContent.contentType === 'video'
    )
    if (video) return { url: video.image, clickable: true, type: 'VIDEO' }
    const voice = message.messageContents.data.find(
      (messageContent: MessageContent) => messageContent.contentType === 'voice'
    )
    if (voice) return { url: voice.voice, clickable: true, type: 'VOICE' }
    return { url: '-', clickable: false }
  }

  const calculateLikes = (message: ContributorMessage) => {
    let likes = 0
    message.messageContents.data.forEach(
      (messageContent: MessageContent) => (likes += messageContent.likes)
    )
    return likes
  }

  useEffect(() => {
    if (!validateSession()) history.push('/')
  }, [])

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
    }
  }, [location.pathname, sessionsState.status.succeededGetCurrentUser])

  useEffect(() => {
    fetchAllMessages(1)
  }, [contributorsState.status.succeededGetContributor])

  useEffect(() => {
    if (selectedTab.reserved) {
      fetchAllReservedMessages(1)
    } else {
      fetchAllMessages(1)
    }
  }, [
    messagesState.status.succeededDeleteContributorMessage,
    messagesState.status.failedDeleteContributorMessage,
  ])

  if (!styles || !user || !contributor) return <></>

  return (
    <>
      <Toast body={toastsState.success.message} />
      <Toast body={toastsState.error.message} error />
      <Toast body={messagesState.success.message} error={false} />
      <Toast body={messagesState.error.message} error={true} />
      {removeMessageModal && (
        <Overlay dismissHandler={() => setRemoveMessageModal(false)}>
          <Modal
            title={'メッセージを削除しますか？'}
            dismissHandler={() => setRemoveMessageModal(false)}
          >
            <ModalButton
              color={'#FFFFFF'}
              textColor={'#828282'}
              label={'キャンセル'}
              onClickHandler={() => setRemoveMessageModal(false)}
            />
            <ModalButton
              color={'#E25047'}
              textColor={'#FFFFFF'}
              label={'削除'}
              onClickHandler={() => removeMessage()}
            />
          </Modal>
        </Overlay>
      )}
      <MainHeader
        heading={distributionLabel()}
        tabs={[
          {
            label: '配信済み',
            value: 'done',
            selected: selectedTab.done,
            changeHandler: () => tabHandler('done'),
          },
          {
            label: '予約中',
            value: 'reserved',
            selected: selectedTab.reserved,
            changeHandler: () => tabHandler('reserved'),
          },
        ]}
      >
        <Button
          label={'新規作成'}
          icon={Create}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={false}
          onClickHandler={() =>
            history.push(
              `/contributors/${
                contributorsState.currentContributor!.id
              }/new_message`
            )
          }
        />
      </MainHeader>
      <MainTable
        columns={{
          scale: '30% 15% 15% 10% 15% 15%',
          items: [
            { label: '内容', grid: '1 / 2' },
            { label: 'メディア', grid: '2 / 3' },
            { label: 'プラン', grid: '3 / 4' },
            { label: 'いいね', grid: '4 / 5' },
            { label: '配信日時', grid: '6 / 7' },
            { label: '編集日時', grid: '7 / 8' },
          ],
        }}
        items={messagesState.currentContributorMessages.data.map(
          (message: ContributorMessage) => ({
            id: message.id,
            data: {
              content: {
                id: message.id,
                value: message.text,
                clickable: true,
                subLabel: false,
                grid: '1 / 2',
              },
              media: {
                id: message.id,
                value: screenMediaTypes(message).url,
                type: screenMediaTypes(message).type,
                clickable: screenMediaTypes(message).clickable,
                subLabel: false,
                grid: '2 / 3',
              },
              plan: {
                id: message.id,
                value: `${message.targetPlanTitle}以上`,
                clickable: false,
                subLabel: false,
                grid: '3 / 4',
              },
              like: {
                id: message.id,
                value: calculateLikes(message),
                clickable: false,
                subLabel: true,
                grid: '4 / 5',
              },
              broadcasted: {
                id: message.id,
                value: message.runAt
                  ? parseDateTime(message.runAt)
                  : parseDateTime(message.updatedAt),
                clickable: false,
                subLabel: true,
                grid: '5 / 6',
              },
              updated: {
                id: message.id,
                value: parseDateTime(message.createdAt),
                clickable: false,
                subLabel: true,
                grid: '6 / 7',
              },
            },
          })
        )}
        selectItemHandler={selectItemHandler}
        option={{
          svg: Bucket,
          clickHandler: (targetId: number) =>
            showRemoveMessageModalHandler(targetId),
        }}
      >
        <Pagination
          meta={messagesState.currentContributorMessages.meta!}
          dispatchedActions={
            selectedTab.reserved
              ? getReservedContributorMessages
              : getContributorMessages
          }
          actionArguments={{
            contributorId: contributor.id,
          }}
        />
      </MainTable>
    </>
  )
}

export default Messages
