import React from 'react'
import '../index.css'
import { useStyles } from 'hooks/useStyles'

const Footer: React.FC = () => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/side_pane_menu',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.footer_wrapper}>
      <a
        href="https://dearst.notion.site/1123ca5882138071b64feafae1a75362"
        target="_blank"
        className={styles.default.terms_and_conditions}
      >
        利用規約
      </a>
    </div>
  )
}

export default Footer
