import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useStyles } from 'hooks/useStyles'
import SignIn from 'components/auth/sign_in'
import ResetPassword from 'components/auth/reset_password'
import ChangePassword from 'components/auth/change_password'
import Toast from 'components/modules/toast'
import MyDearLogo from 'images/cms_logo_mydear.png'
import SignUp from 'components/auth/sign_up'
import Verify from 'components/auth/verify'
import { CombineReducers } from 'models'
import { useSelector } from 'react-redux'
import { ToastsState } from 'models/toasts'
import TermsAndConditions from './terms_and_conditions'

const Auth: React.FC<{ requireReAuth: boolean }> = props => {
  const history = useHistory()
  const [styles, setStyles] = useStyles({
    page: 'components/auth',
  })
  const toastsState: ToastsState = useSelector((state: CombineReducers) => {
    return state.toasts
  })

  if (!styles) return <></>

  return (
    <>
      <Toast body={toastsState.success.message} />
      <Toast body={toastsState.error.message} error />
      <div className={styles.default.auth_header}>
        <div
          className={styles.default.dear_logo_btn}
          onClick={() => history.push('/')}
        >
          <div className={styles.default.dear_logo}>
            <img src={MyDearLogo} />
          </div>
          <span>管理画面</span>
        </div>
        <div className={styles.default.auth_message}>
          {props.requireReAuth && (
            <Toast body={'再ログインしてください。'} error={true} />
          )}
        </div>
      </div>
      <div className={styles.default.auth_body}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/sign_in" />
          </Route>
          <Route exact path="/sign_in" component={SignIn} />
          <Route exact path="/sign_up" component={SignUp} />
          <Route exact path="/verify" component={Verify} />
          <Route exact path="/reset_password" component={ResetPassword} />
          <Route exact path="/change_password" component={ChangePassword} />
        </Switch>
      </div>
    </>
  )
}

export default Auth
