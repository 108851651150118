import { getContributor } from 'actions/contributors'
import { getMemberships } from 'actions/memberships'
import { getSubscriptionTiers } from 'actions/subscription_tiers'
import Button from 'components/modules/button'
import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import {
  SubscriptionTier,
  SubscriptionTiersState,
} from 'models/subscription_tiers'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

const MembershipPlans: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams<{
    id: string
  }>()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const subscriptionTiersState: SubscriptionTiersState = useSelector(
    (state: CombineReducers) => {
      return state.subscriptionTiers
    }
  )

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
      dispatch(
        getSubscriptionTiers.started({
          contributorId:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
    }
  }, [])

  return (
    <>
      <MainHeader heading={'メンバーシップ'}>
        <Button
          label={'誕生月'}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={false}
          onClickHandler={() =>
            history.push(`/contributors/${params.id}/membership_birthdays`)
          }
        />
      </MainHeader>
      <MainTable
        columns={{
          scale: '100%',
          items: [{ label: 'タイトル', grid: '1 / 2' }],
        }}
        items={subscriptionTiersState.subscriptionTiers
          .filter(
            (subscriptionTier: SubscriptionTier) =>
              subscriptionTier.tier !== 'free'
          )
          .map((subscriptionTier: SubscriptionTier) => ({
            id: subscriptionTier.id,
            data: {
              title: {
                id: subscriptionTier.id,
                value: subscriptionTier.title,
                clickable: true,
                subLabel: false,
                grid: '1 / 2',
              },
            },
          }))}
        selectItemHandler={(subscriptionTierId: number) =>
          history.push(
            `/contributors/${params.id}/subscription_tiers/${subscriptionTierId}`
          )
        }
      />
    </>
  )
}

export default MembershipPlans
