import contributorDeserializer from 'deserializers/contributor'
import messageContentsDeserializer from 'deserializers/message_contents/many'
import { ContributorMessage } from 'models/messages'

function contributorMessageDeserializer(input: {
  data: any
  meta?: any
}): ContributorMessage {
  return {
    id: input.data.id,
    text: input.data.text,
    messageType: input.data.message_type,
    sendingType: input.data.sending_type,
    premiumChatRequest: input.data.premium_chat_request,
    targetPlan: input.data.target_plan,
    targetPlanTitle: input.data.target_plan_title,
    runAt: input.data.run_at,
    createdAt: input.data.created_at,
    updatedAt: input.data.updated_at,
    messageContents: messageContentsDeserializer({
      data: input.data.message_contents,
    }),
    contributor: contributorDeserializer({ data: input.data.contributor }),
  }
}

export default contributorMessageDeserializer
